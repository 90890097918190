<template>
    <div style='width:850px;'>
        <el-form :model="form" ref="formRef" label-width="180px" :disabled='isDisabled' v-loading='listLoading'>
            <el-form-item label="培训机构名称：" prop="name" :rules="[{required:true,message:'请输入培训机构名称',trigger:'blur'}]">
                <el-input v-model.trim="form.name" placeholder='请输入培训机构名称'></el-input>
            </el-form-item>
            <el-form-item label="联系人：" prop="contactPerson" :rules="[{required:true,message:'请输入联系人',trigger:'blur'}]">
                <el-input v-model.trim="form.contactPerson" placeholder='请输入联系人'></el-input>
            </el-form-item>
            <el-form-item label="联系人手机号：" prop="contactPersonPhoneNumber" :rules="[{required:true,message:'请输入联系人手机号',trigger:'blur'},{required:true,validator:isPhoneNumberNum,trigger:'blur'}]">
                <el-input v-model.trim="form.contactPersonPhoneNumber" placeholder='请输入联系人手机号'></el-input>
            </el-form-item>
            <div style='display:flex;'>
                <el-form-item label='省：' prop='provinceID'>
                    <el-select v-model="form.provinceID" placeholder="请选择" clearable @change='handleProChange' v-loading='loading1' @clear='clearProvince'>
                        <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='市：' prop='cityID'>
                    <el-select v-model="form.cityID" placeholder="请选择" clearable @change='handleCityChange' v-loading='loading2' @clear='clearCity'>
                        <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='区/县：' prop='regionID'>
                    <el-select v-model="form.regionID" placeholder="请选择" clearable v-loading='loading3' @clear='clearRegion'>
                        <el-option
                        v-for="item in options3"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>            
            <el-form-item label="详细地址：" prop="address" :rules="[{required:true,message:'请输入详细地址',trigger:'blur'}]">
                <el-input v-model.trim="form.address" placeholder='请输入详细地址'></el-input>
            </el-form-item>
            <el-form-item label="办学规模(人)：" prop="scaleCount" :rules="[{required:true,message:'请输入办学规模',trigger:'blur'}]">
                <el-input v-model.trim="form.scaleCount" placeholder='请输入办学规模'></el-input>
            </el-form-item>
            <el-form-item label="注册资本(万)：" prop="registeredCapital" :rules="[{required:true,message:'请输入注册资本',trigger:'blur'}]">
                <el-input v-model.trim="form.registeredCapital" placeholder='请输入注册资本'></el-input>
            </el-form-item>
            <el-form-item label="微信小程序ID：" prop="wechatAppletID" :rules="[{required:true,message:'请输入微信小程序ID',trigger:'blur'}]">
                <el-input v-model.trim="form.wechatAppletID" placeholder='请输入微信小程序ID'></el-input>
            </el-form-item>
            <el-form-item label="微信小程序首页路径：" prop="wechatAppletHomePageUrl" :rules="[{required:true,message:'请输入微信小程序首页路径',trigger:'blur'}]">
                <el-input v-model.trim="form.wechatAppletHomePageUrl" placeholder='请输入微信小程序首页路径'></el-input>
            </el-form-item>
            <el-form-item label='审核状态：' prop='status' >
                <el-select v-model="form.status" placeholder="请选择" clearable @change='handleProChange' :disabled='true'>
                    <el-option
                    v-for="item in options4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='是否启用：' prop='isEnable'>
                <el-switch v-model="form.isEnable" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
            </el-form-item>                      
        </el-form>
        <el-form>
            <el-form-item style='text-align:center' :disabled='false'>
                <el-button type='primary' @click='nextStep'>{{buttonTxt1}}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Bus from '../../../util/bus'; // 组件传值的中转站
import { validPhone } from "../../../util/validate";
import {getAreaDictionaryList} from '@/api/api'
export default {
    data(){
        return {
            options1:[],
            options2:[],
            options3:[],
            options4:[
                {label:'未审核',value:0},
                {label:'未通过',value:2},
                {label:'已通过',value:1},
            ],
            loading1:false,
            loading2:false,
            loading3:false,
            form:{
                name:'',
                contactPerson:'',
                contactPersonPhoneNumber:'',
                provinceID:'',
                cityID:'',
                regionID:'',
                address:'',
                scaleCount:'',
                registeredCapital:'',
                wechatAppletID:'',
                wechatAppletHomePageUrl:'',
                isEnable:true,
                status:0
            },
            isDisabled:false, // 是否禁用
            buttonTxt1:'下一步', // 按钮文本内容
            listLoading:false,
        }
    },
    props:['data'],
    watch:{
        data:function(newVal){
            if(newVal){
                this.listLoading = true
                // 数据回显
                this.form = {
                    name:newVal.Name,
                    contactPerson:newVal.ContactPerson,
                    contactPersonPhoneNumber:newVal.ContactPersonPhoneNumber,
                    address:newVal.Address,
                    scaleCount:newVal.ScaleCount,
                    registeredCapital:newVal.RegisteredCapital,
                    wechatAppletID:newVal.WechatAppletID,
                    wechatAppletHomePageUrl:newVal.WechatAppletHomePageUrl,
                    isEnable:newVal.IsEnable,
                    status:newVal.Status
                }
                if(newVal.ProvinceID){
                    this.form.provinceID = newVal.ProvinceID
                }
                if(newVal.CityID){
                    this.form.cityID = newVal.CityID
                }
                if(newVal.RegionID){
                    this.form.regionID = newVal.RegionID
                }

                var params = {
                    level:0
                }
                setTimeout(() => {this.getArea(params)},1000)

                var params1 = {
                    level:1,
                    parentId:this.form.provinceID
                } 
                setTimeout(() => {this.getArea(params1)},1000)

                var params2 = {
                    level:2,
                    parentId:this.form.cityID
                }
                setTimeout(() => {this.getArea(params2)},1000)

                
                if(newVal.Status == 1){
                    this.isDisabled = true // 审核通过，表单禁用
                }else{
                    this.isDisabled = false
                }   
                setTimeout(() =>{
                    this.listLoading = false
                },600)
                
            }         
        }
    },
    methods:{
        //验证手机号
        isPhoneNumberNum(rule, value, callback){
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        },
        // 获取省市区信息
        getArea(params){
            switch(params.level){
                case 0:
                    // 得到省
                    this.getProvince(params)
                    break;
                case 1:
                    // 得到市
                    this.getCity(params)
                    break;
                case 2:
                    // 得到区/县
                    this.getRegion(params)
                    break;
            }
        },
        getProvince(params){
            this.loading1 = true
            getAreaDictionaryList(params).then(res => {
                this.loading1 = false
                if(res.data.Success){
                    var data = res.data.Response
                    if(data){
                        this.options1 = data.map(item => {
                            var obj = {}
                            obj.label = item.Name
                            obj.value = item.ID
                            return obj
                        }).reverse()
                    }else{return}                   
                }
            }).catch(() => {this.loading1 = false})
        },
        getCity(params){
            this.loading2 = true
            getAreaDictionaryList(params).then(res => {
                this.loading2 = false
                if(res.data.Success){
                    var data = res.data.Response
                    if(data){
                        this.options2 = data.map(item => {
                            var obj = {}
                            obj.label = item.Name
                            obj.value = item.ID
                            return obj
                        }).reverse()
                    }else{return}                   
                }
            }).catch(() =>{this.loading2 = false})
        },
        getRegion(params){
            this.loading3 = true
            getAreaDictionaryList(params).then(res => {
                this.loading3 = false
                if(res.data.Success){
                    var data = res.data.Response
                    if(data){
                        this.options3 = data.map(item => {
                            var obj = {}
                            obj.label = item.Name
                            obj.value = item.ID
                            return obj
                        }).reverse()
                    }else{return}
                }
            }).catch(() => {this.loading3 = false})
        },
        handleProChange(value){
            if(value){
                var params = {
                    level:1,
                    parentId:value
                }
                this.getArea(params) 
                this.clearCity()
                this.clearRegion()
            }else{return}           
        },
        handleCityChange(value){
            if(value){
                var params = {
                    level:2,
                    parentId:value
                }
                this.getArea(params)
                this.clearRegion()
            }else{return}          
        },
        // 清空省
        clearProvince(){
            this.options2 = []
            this.options3 = []
            this.form.provinceID = ''
            this.form.cityID = ''
            this.form.regionID = ''
        },
        // 清空市
        clearCity(){
            this.options3 = []
            this.form.cityID = ''
            this.form.regionID = ''
        },
        // 清空区/县
        clearRegion(){
            this.form.regionID = ''
        },
        // 下一步
        nextStep(){
            this.$refs.formRef.validate((valid) => {
                if(valid){                   
                    Bus.$emit('getFormValue',Object.assign({},this.form)) // 将表单数据进行合并
                    this.$emit('canEnter',this.form) // 可以进入下一步
                }else{
                    
                }
            })
        },
    },
    created(){
        // 获取省份信息
        var params = {
            level:0,
            parentId:''
        }
        this.getArea(params)
    },
    mounted(){
        // 点击上一步，得到表单数据
        Bus.$on('getPrevFormData',value => {
            this.form = {...value}
        })
    }
}
</script>

<style lang="stylus" scoped>

</style>