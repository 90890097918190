<template>
    <div>
        <h4 class='title'>机构信息登记</h4>
        <!-- 内容 -->
        <el-tabs type="border-card" v-model='activeName'>
            <el-tab-pane label="编辑基本信息" name='tab1' :key='tab1' >
                <BasicInformation  @canEnter='enterStep' :data='data'></BasicInformation>
            </el-tab-pane>
            <el-tab-pane label="上传资质照片" name='tab2' :key='tab2' :disabled='isNoNextStep  '>
                <PhotoInformation :data='data' @toFirstTab='isToFirstTab' :getInfo='data'></PhotoInformation>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import BasicInformation from './BasicInformation.vue';
import PhotoInformation from './PhotoInformation.vue'
import {getAgencyInfo} from '@/api/api';
export default {
    components:{BasicInformation,PhotoInformation},
    data(){
        return {
            activeName:'tab1', // 激活的tab名字
            tab1:true,
            tab2:false,
            isNoNextStep:true,
            form1:{},
            data:{}, // 获得到的机构信息数据
        }
    },
    watch:{
        data:function(val,a){
            // console.log(val)
            // var arr = []
            // if(val){
            //     arr.push(val.Name,val.ContactPerson,val.ContactPersonPhoneNumber,val.ScaleCount,val.RegisteredCapital,val.WechatAppletID,val.WechatAppletHomePageUrl)
            //     if(arr.includes('') || arr.includes(null)){
            //         console.log(11)
            //         this.isNoNextStep = true
            //         this.activeName = 'tab1'
            //     }else{
            //         console.log(22)
            //         this.isNoNextStep = false
            //     }
            // }else{
            //     console.log(33)
            //     this.isNoNextStep = true
            // }
            
            
            // console.log(val.Address)
            if(val){
                this.isNoNextStep = false
            }else{
                this.isNoNextStep = true
            }
        }
    },
    methods:{
        enterStep(data){
            this.form1 = {...data}          
            this.isNoNextStep = false
            this.activeName = 'tab2'
            // this.tab1 = false
            // this.tab2 = true   不能要false，ture了，否则上部分表单数据消失
        }, 
        getData(){
            getAgencyInfo().then(res => {
                if(res.data.Success){
                    if(res.data.Response){
                        this.data = res.data.Response
                    }else{return}                   
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {})
        },
        // 返回到第一页
        isToFirstTab(value){
            this.activeName = 'tab1'
            this.tab1 = true
            this.tab2 = false
        } 
    },
    created(){
        this.getData()
    },
    mounted(){}
}
</script>

<style lang="stylus" scoped>
    .title{
        margin:0;
        margin-bottom: 20px;
    }
</style>